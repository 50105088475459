import React from "react";
import { useItemsFilter } from "../_actions/items";
import OrderCard from "./OrderCard";
import NoCandidate from "../profile/NoCandidate";
import SubscriptionCard from "./SubscriptonCard";

function ListSubscriptions({ status = "active", old = false }) {
    const { isLoading, isSuccess, data } = useItemsFilter(
        "qd_subscriptions",
        `?sort=-start_date&limit=-1&filter[status][_eq]=${status}&filter[is_archived][_eq]=${old ? "true" : "false"}&fields=*,user.first_name,user.last_name,user.matrimonial_profiles.id,user.matrimonial_profiles.main_photo.*,user.matrimonial_profiles.gender,payments.*`,
        true
    );

    console.log("List subscriptions", data);
    return (
        <div>
            {isSuccess && data?.length === 0 && (
                <NoCandidate text={`No ${status} subscription found`} />
            )}
            {isSuccess && <p>Total {data?.length} subscriptions</p>}
            {isSuccess && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px 0",
                    }}
                >
                    {data.map((item) => (
                        <SubscriptionCard
                            order={item}
                            user={item.user}
                            profile={item.user?.matrimonial_profiles?.[0]}
                            payment={item.payments?.[0]}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default ListSubscriptions;
