import styles from "./styles/TV.module.css";
import React, { useState, useEffect } from "react";
import { useItemsFilterNew, useSingleItem } from "../_actions/items";
import { Checkbox, Icon, Input } from "semantic-ui-react";
import ProfileTV from "./ProfileTV";
import { TV_SLIDESHOW_DURATION } from "../constants";

function TV() {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [enable_slideshow, setEnable_slideshow] = useState(false);
    const [sno, setSNo] = useState(1);
    const { isLoading, isSuccess, data, isFetching } = useItemsFilterNew(
        "qd_matrimonial_profile",
        `?filter[serial_number][_eq]=${sno}&fields=*,education.name,new_education.qdb_lookup_id.name,location.name,native_place.name,birth_place.name,blood_group.name,gotra.name,addresses.*,addresses.country.name`,
        !!sno,
        true
    );

    useEffect(() => {
        let intervalId;

        if (enable_slideshow) {
            intervalId = setInterval(() => {
                setSNo(getRandomProfileSerialNumber());
            }, TV_SLIDESHOW_DURATION * 1000);
        }

        // Clear the interval on component unmount or when enable_slideshow is false
        return () => clearInterval(intervalId);
    }, [enable_slideshow]); // Empty dependency array ensures the effect runs only once on mount

    console.log("TV Response", data);

    const handleFullScreenToggle = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }

        setIsFullScreen(!isFullScreen);
    };

    const bg_col =
        data?.data?.[0]?.gender === "Female"
            ? "#ff9ccd"
            : data?.data?.[0]?.gender === "Male"
                ? "#94e8ff"
                : "#faf68e";
    return (
        <div className={styles.wrapper}>
            <div
                className={styles.input_bar}
                style={{
                    backgroundColor: bg_col,
                }}
            >
                <img
                    // src="https://api.qureal.com/passets/16480c10-a1de-4546-9a03-68323ab3b412.png"
                    src="https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b"
                    alt="LOGO"
                    className={styles.logo}
                />
                <Input
                    type="number"
                    value={sno}
                    onChange={(e) => {
                        setEnable_slideshow(false);
                        setSNo(e.target.value);
                    }}
                    loading={isLoading || isFetching}
                    icon={
                        isSuccess && !(isLoading || isFetching) ? (
                            data?.data?.length > 0 ? (
                                <Icon
                                    name="checkmark"
                                    circular
                                    inverted
                                    color="green"
                                />
                            ) : (
                                <Icon
                                    name="times"
                                    circular
                                    inverted
                                    color="red"
                                />
                            )
                        ) : isLoading || isFetching ? (
                            <Icon name="laptop" color="blue" />
                        ) : (
                            <></>
                        )
                    }
                />
                <Checkbox
                    label="Slideshow"
                    style={{ fontSize: "1.2em" }}
                    checked={enable_slideshow}
                    onChange={(e, data) => setEnable_slideshow(data.checked)}
                />
                <Icon
                    name="expand"
                    style={{
                        fontSize: "1.2em",
                        color: "gray",
                        paddingLeft: "15px",
                        cursor: "pointer",
                    }}
                    onClick={handleFullScreenToggle}
                />
            </div>
            {isSuccess && data?.data?.length > 0 && (
                <ProfileTV data={data?.data?.[0]} bg_col={bg_col} />
            )}
        </div>
    );
}

export default TV;

function getRandomProfileSerialNumber() {
    const ranges = [
        { min: 1, max: 305 },
        { min: 311, max: 319 },
        { min: 351, max: 617 },
        { min: 621, max: 627 },
        { min: 1001, max: 1007 },
        { min: 1021, max: 1029 }
    ];

    // Choose a random range from the array
    // const randomRange = ranges[Math.floor(Math.random() * ranges.length)];

    // choose random range from the array ranges with bigger element lengith with more probablity
    const totalLength = ranges.reduce((acc, range) => acc + (range.max - range.min + 1), 0);
    const randomWeight = Math.random() * totalLength;
    let randomRange = null;

    let cumulativeWeight = 0;
    for (let range of ranges) {
        cumulativeWeight += (range.max - range.min + 1);
        if (randomWeight < cumulativeWeight) {
            randomRange = range;
            break;
        }
    }

    // Generate and return a random number within the chosen range
    return (
        Math.floor(Math.random() * (randomRange.max - randomRange.min + 1)) +
        randomRange.min
    );
}
