import classNames from "classnames";
import React from "react";
import ProfileTVPage from "../tv/ProfileTVPage";
import ProfileCardSquare from "./ProfileCardSquare";
import styles from "./styles/PageGrid.module.css";
import stylesBg from "./styles/PrintIndex.module.css";

function PageGridTV({ data }) {
    const serial_number = data?.serial_number;

    if (!serial_number) {
        // return <></>
        return (
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "#fff1cf",
                    border: "1px solid black",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2em",
                    color: "#bbb",
                }}
            >
                NO PROFILE
            </div>
        );
    }

    // return (
    //     <h3>
    //         {page_no} : {serial_number}
    //     </h3>
    // );

    console.log("TV Page data", data);

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                position: "relative",
            }}
        >
            <ProfileTVPage data={data} bg_col="yellow" />
        </div>
    );
}

export default PageGridTV;
