import React, { useEffect } from "react";
import PayButton from "./PayButton";
import { useCurrentUser } from "../_actions/auth";
import {
    useCreatePayment,
    useVerifyPayment,
} from "../_actions/mutations/payment";
import { useItemsFilterNew } from "../_actions/items";

function PaymentManager({
    order_type,
    order_data,
    amount = 89,
    onSuccess = () => { },
    onFailed = () => { },
    trigger,
}) {
    const { isLoading, isSuccess, data, refetch } = useItemsFilterNew(
        "qd_payments",
        `?filter[user][_eq]=$CURRENT_USER&filter[status][_eq]=pending&filter[amount][_eq]=${amount}`,
        true
    );
    const mutationCreatePayment = useCreatePayment();
    const mutationVerifyPayment = useVerifyPayment();
    const me = useCurrentUser();

    console.log("PM data", data);
    console.log("Me", me);

    useEffect(() => {
        if (
            isSuccess &&
            data?.data?.length <= 0 &&
            me?.id &&
            mutationCreatePayment.isIdle
        ) {
            //create
            console.log("Creating new payment line");
            mutationCreatePayment.mutate({
                amount: amount,
                user: me?.id,
                notes: {
                    user_id: me?.id,
                    amount: amount,
                    order_type: order_type,
                    ...order_data,
                },
            });
        }
    }, [isSuccess, data]);

    return (
        <div>
            {(isLoading || mutationCreatePayment.isLoading) && (
                <p>Loading...</p>
            )}
            {isSuccess && data?.data?.length > 0 && (
                <PayButton
                    order={data?.data?.[0]}
                    price={amount}
                    notes={{
                        user_id: me?.id,
                        amount: amount,
                        order_type: order_type,
                        ...order_data,
                    }}
                    onSuccess={(response) => {
                        onSuccess(response);
                        mutationVerifyPayment.mutate({
                            ...response,
                            notes: {
                                user_id: me?.id,
                                amount: amount,
                                order_type: order_type,
                                ...order_data,
                            },
                        });
                        refetch();
                    }}
                    onFailed={() => {
                        onFailed();
                    }}
                    //=========================
                    // name="Shivam Agrawal"
                    // email="shivam@qureal.com"
                    // mobile={8871830420}
                    trigger={trigger}
                />
            )}
        </div>
    );
}

export default PaymentManager;

// razorpay key, secret (shivam301296)
// rzp_test_oOQeCHJoMaCTwj
// 6WQQ4KEJNJ9TFD0rfLm6Jkwt
