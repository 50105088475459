import React, { useState, useEffect } from "react";
import Screen from "../commons/Screen";
import SearchFilters from "./SearchFilters";
import SearchSearch, { GenderSwitch } from "./SearchSearch";
import { useDebounce, useMediaQuery } from "@uidotdev/usehooks";
import CandidateList from "./CandidateList";
import { useCurrentUser } from "../_actions/auth";
import { useItemsFilterNew } from "../_actions/items";
import { Button, Pagination } from "semantic-ui-react";
import WhatsAppButton from "../commons/WhatsAppButtion";

function SearchHome({
    per_page = 6,
    gender = "Male",
    show_gender_switch = false,
}) {
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const [filter_gender, setFilter_gender] = useState("");
    const [filter, setFilter] = useState(
        // `{"_and":[{"gender":{"_eq":"${gender}"}},{"status":{"_eq":"Active"}}]}`
        // `{"_and":[{"gender":{"_eq":"${gender}"}}]}`
        null
    );
    // const [filter, setFilter] = useState(``);
    const [search_query, setSearchQuery] = useState("");
    const debounced_search_query = useDebounce(search_query, 300);
    //pagination
    const [total, setTotal] = useState(1);
    const [num_page, setNumPage] = useState(1);
    const [page_no, setPageNo] = useState(1);
    //----------
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "qd_matrimonial_profile",
        // `?limit=${per_page}&offset=${per_page * (page_no - 1)}${
        //     debounced_search_query ? `&search=${debounced_search_query}` : ""
        // }&filter=${filter}&fields=*,location.name&meta=*`,
        `?limit=${per_page}&offset=${per_page * (page_no - 1)
        }&filter=${filter}&fields=*,location.name,birth_place.name&meta=*`,
        // }&filter[gender][_eq]=${gender}&fields=*,location.name,birth_place.name&meta=*`,
        !!gender,
        true
    );

    useEffect(() => {
        setFilter_gender(gender);
    }, [gender]);

    useEffect(() => {
        setPageNo(1);
    }, [filter, search_query]);

    //save filter in localstorage
    useEffect(() => {
        if (filter !== `{"_and":[{"gender":{"_eq":"Female"}},{"status":{"_eq":"Active"}}]}` && filter !== `{"_and":[{"gender":{"_eq":"Male"}},{"status":{"_eq":"Active"}}]}` && filter !== `{"_and":[{"gender":{"_eq":""}},{"status":{"_eq":"Active"}}]}`) {
            if (filter) {
                localStorage.setItem("qm_filter", filter);
            }
        }
    }, [filter])

    //recover filter from localstorage
    useEffect(() => {

        setTimeout(() => {
            const qm_filter = localStorage.getItem("qm_filter");
            console.log("recovered filters", qm_filter)
            // alert(`Got filter: ${qm_filter}`);
            if (localStorage.getItem("qm_filter")) {
                setFilter(localStorage.getItem("qm_filter"));
            } else {
                setFilter(`{"_and":[{"gender":{"_eq":"${gender}"}}]}`)
            }
        }, 500)

    }, [])

    useEffect(() => {
        if (isSuccess) {
            const total_records = data?.meta?.filter_count;
            if (total_records > 0) {
                setTotal(total_records);
                setNumPage(Math.ceil(total_records / per_page));
            }
            console.log("User Response", data);
        }
    }, [isSuccess, data]);

    console.log("SearchHome data", data);

    return (
        <Screen
            title="Find your soulmate"
            wrapper_styles={{
                padding: "7px",
            }}
            actions={[
                ...(show_gender_switch
                    ? [
                        <GenderSwitch
                            value={filter_gender}
                            setValue={(new_val) => setFilter_gender(new_val)}
                        />,
                    ]
                    : []),
                <SearchSearch
                    value={search_query}
                    onChange={(new_val) => setSearchQuery(new_val)}
                    isMobile={isMobile}
                />,
                <SearchFilters
                    // gender={gender}
                    gender={filter_gender}
                    value={filter}
                    onChange={(new_val) => setFilter(new_val)}
                    search_term={debounced_search_query}
                />,
                ...(isFilterApplied(filter)
                    ? [
                        <Button onClick={() => {
                            setFilter(`{"_and":[{"gender":{"_eq":"${gender}"}},{"status":{"_eq":"Active"}}]}`);
                            localStorage.setItem("qm_filter", `{"_and":[{"gender":{"_eq":"${gender}"}},{"status":{"_eq":"Active"}}]}`);
                        }}>Reset Filters</Button>
                    ] : []),
            ]}
        >
            {isSuccess && <p>Total {data?.meta?.filter_count} results found</p>}
            {isSuccess && <CandidateList data={data?.data} meta={data?.meta} />}
            <Pagination
                activePage={page_no}
                totalPages={num_page}
                onPageChange={(e, { activePage }) => setPageNo(activePage)}
                ellipsisItem={isMobile ? null : undefined}
                firstItem={isMobile ? null : undefined}
                lastItem={isMobile ? null : undefined}
            />
            <WhatsAppButton />
        </Screen>
    );
}

export default SearchHome;


function isFilterApplied(filter) {
    return filter !== `{"_and":[{"gender":{"_eq":"Female"}},{"status":{"_eq":"Active"}}]}` && filter !== `{"_and":[{"gender":{"_eq":"Male"}},{"status":{"_eq":"Active"}}]}` && filter !== `{"_and":[{"gender":{"_eq":""}},{"status":{"_eq":"Active"}}]}`
}