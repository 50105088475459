import { Icon } from "semantic-ui-react";
import { getProfilePhoto } from "../_helpers/files";
import styles from "./styles/ProfileTV.module.css";
import React, { useMemo } from "react";
import { convertToAMPM, formatTime } from "../_helpers/dt";
import classNames from "classnames";

const EDUCATION_MAPPING = {
    "Management and Business Administration": "MBA",
    "Engineering": "Eng.",
    "Accounting and Finance": "BAF",
    "Banking and Insurance": "BI",
    "Humanities and Social Sciences": "HSS",
}

function ProfileTV({ data, bg_col }) {
    const height = useMemo(() => {
        if (data.height > 0) {
            const inches = data.height / 2.54;
            const feet = Math.floor(inches / 12);
            const remainingInches = Math.round(inches % 12);
            return {
                feet: feet,
                inch: remainingInches,
            };
        }
        return null;
    }, [data.height]);

    const mobiles = useMemo(() => {
        const addrs = data.addresses;
        const all =
            addrs?.filter((item) => !!item.mobile).map((item) => item.mobile) ||
            [];
        const all_alternate =
            addrs
                ?.filter((item) => !!item.mobile_alternative)
                .map((item) => item.mobile_alternative) || [];
        return [...all, ...all_alternate] || [];
    }, [data?.addresses]);


    let edu = data.new_education?.length > 0 ? data.new_education.map((edu_item, index) => {
        return `${EDUCATION_MAPPING[edu_item?.qdb_lookup_id?.name] || edu_item?.qdb_lookup_id?.name}${index < data.new_education.length - 1 ? ", " : ""}`
    }).join("") + ", " : "";
    edu += data.education_stream && `${data.education_stream}`

    return (
        <div className={styles.wrapper} style={{
            fontSize: "1.1em",
        }}>
            <div
                className={classNames(
                    styles.box_left,
                    data.gender === "Male" ? styles.bg_male : styles.bg_female
                )}
                style={{
                    backgroundColor: bg_col,
                }}
            >
                <img
                    src={getProfilePhoto(data.main_photo, data.gender)}
                    className={styles.main_photo}
                    alt="Main"
                />
            </div>
            <div className={styles.box_right}>
                <h1 className={styles.name}>
                    {data.first_name}{" "}
                    {data.middle_name ? `${data.middle_name} ` : ""}
                    {data.last_name}
                </h1>
                <table className={styles.highlight_table}>
                    {/* <Row label="Education" value={data.education?.name} /> */}
                    <Row label="Education" value={edu} />
                    <Row
                        label="Designation"
                        value={`${data.designation || "NA"}, ${data.organization_name || ""
                            }`}
                    />
                    <Row
                        label="Income"
                        value={`${data.income} ${data.income_currency} per annum`}
                    />
                    <Row label="Work Location" value={data?.new_location} />
                    <Row
                        label="Height"
                        value={`${height.feet} feet ${height.inch} inch`}
                    />
                    <Row
                        label="Native Place"
                        // value={data?.native_place?.name}
                        value={data?.new_native_place}
                    />
                    <Row
                        label="Birthday"
                        value={`${data.brithday}, ${convertToAMPM(
                            data.birth_time
                        )}`}
                    />
                    {/* <Row label="Birth Place" value={data?.birth_place?.name} /> */}
                    <Row label="Birth Place" value={data?.new_birth_place} />
                    <Row label="Blood Group" value={data.blood_group?.name} />
                    <Row label="Gotra" value={data.gotra?.name} />
                    <Row
                        label="Mobile"
                        value={mobiles.splice(0, 3).join(",  ")}
                    // value={mobiles.join(",  ")}
                    />
                    <Row label="Email" value={data.email} />
                    {data.addresses?.length > 0 &&
                        data.addresses.map((item, index) => {
                            if (index > 0) {
                                return <></>;
                            }
                            return (
                                <Row
                                    label="Address"
                                    value={
                                        <div>
                                            {" "}
                                            <span>
                                                {item.name},{" "}
                                                {item.address?.length > 70
                                                    ? `${item.address?.slice(
                                                        0,
                                                        70
                                                    )}...`
                                                    : item.address}
                                            </span>
                                            <br />
                                            {item.city}, {item.country?.name},{" "}
                                            {item.pincode}
                                            <br />
                                        </div>
                                    }
                                />
                            );
                        })}
                </table>
                <table className={styles.br_table}>
                    <tr>
                        <td className={styles.label}>Brothers Married</td>
                        <td>{data.brothers_married || "-"}</td>
                        <td className={styles.label}>Brothers Unmarried</td>
                        <td>{data.brothers_unmarried || "-"}</td>
                    </tr>
                    <tr>
                        <td className={styles.label}>Sisters Married</td>
                        <td>{data.sisters_married || "-"}</td>
                        <td className={styles.label}>Sisters Unmarried</td>
                        <td>{data.sisters_unmarried || "-"}</td>
                    </tr>
                </table>
            </div>
            <h2
                className={styles.serial_number}
                style={{
                    backgroundColor: bg_col,
                }}
            >
                {data.serial_number}
            </h2>
        </div>
    );
}

export default ProfileTV;

function Row({ label, value }) {
    return (
        <tr>
            <td className={styles.label}>{label}</td>
            <td className={styles.max_lines_1}>{value || "-"}</td>
        </tr>
    );

    // return value ? (
    //     <tr>
    //         <td className={styles.label}>{label}</td>
    //         <td className={styles.max_lines_1}>{value}</td>
    //     </tr>
    // ) : (
    //     <></>
    // );
}
